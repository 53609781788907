import { Attribute, JsonApiModel, JsonApiModelConfig } from 'mpugach-angular2-jsonapi';

@JsonApiModelConfig({
    type: 'networks'
})
export class Network extends JsonApiModel {
    @Attribute()
    networkType: string;

    @Attribute()
    address: string;

    @Attribute()
    mask: string;

    @Attribute()
    family: string;
}
