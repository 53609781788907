import { Attribute, JsonApiModel, JsonApiModelConfig } from 'mpugach-angular2-jsonapi';

@JsonApiModelConfig({
    type: 'users'
})
export class User extends JsonApiModel {
    @Attribute()
    public name: string;

    @Attribute()
    public email: string;

    @Attribute()
    public createdAt: Date;

    @Attribute()
    public updatedAt: Date;
}

