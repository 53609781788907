import { parseISO } from 'date-fns';
import { isEqual, cloneDeep, forEach, extend } from 'lodash';
import find from 'lodash-es/find';
import includes from 'lodash-es/includes';
import { Injectable, NgModule } from '@angular/core';
import { HttpErrorResponse, HttpHeaders, HttpClient, HttpClientModule } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { stringify } from 'qs';
import 'reflect-metadata';

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
class JsonApiNestedModel {
  /**
   * @param {?=} data
   */
  constructor(data) {
    this.nestedDataSerialization = false;
    if (data) {
      Object.assign(this, data);
    }
  }
  /**
   * @return {?}
   */
  get modelConfig() {
    return Reflect.getMetadata('JsonApiModelConfig', this.constructor);
  }
  /**
   * @param {?} data
   * @return {?}
   */
  fill(data) {
    Object.assign(this, data);
  }
  /**
   * @return {?}
   */
  serialize() {
    return this.transformSerializedNamesToPropertyNames();
  }
  /**
   * @protected
   * @template T
   * @return {?}
   */
  transformSerializedNamesToPropertyNames() {
    /** @type {?} */
    const serializedNameToPropertyName = this.getModelPropertyNames();
    /** @type {?} */
    const properties = {};
    Object.keys(serializedNameToPropertyName).forEach(
    /**
    * @param {?} serializedName
    * @return {?}
    */
    serializedName => {
      if (this && serializedName !== 'nestedDataSerialization') {
        properties[serializedNameToPropertyName[serializedName]] = this[serializedName];
      }
    });
    return properties;
  }
  /**
   * @protected
   * @return {?}
   */
  getModelPropertyNames() {
    return Reflect.getMetadata('AttributeMapping', this) || [];
  }
}
if (false) {
  /** @type {?} */
  JsonApiNestedModel.prototype.nestedDataSerialization;
  /* Skipping unhandled member: [key: string]: any;*/
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/** @type {?} */
const DEFAULT_OPTIONS = {
  nullValue: false,
  hasMany: false
};
/**
 * @template T
 */
class JsonModelConverter {
  /**
   * @param {?} model
   * @param {?=} options
   */
  constructor(model, options = {}) {
    this.modelType = model; // <ModelType<T>>model
    this.options = Object.assign({}, DEFAULT_OPTIONS, options);
  }
  /**
   * @param {?} value
   * @return {?}
   */
  mask(value) {
    if (!value && !this.options.nullValue) {
      if (this.options.hasMany) {
        return [];
      }
      return new this.modelType();
    }
    /** @type {?} */
    let result = null;
    if (this.options.hasMany) {
      if (!Array.isArray(value)) {
        throw new Error(`ERROR: JsonModelConverter: Expected array but got ${typeof value}.`);
      }
      result = [];
      for (const item of value) {
        if (item === null) {
          continue;
        }
        /** @type {?} */
        let temp;
        if (typeof item === 'object') {
          temp = new this.modelType();
          temp.fill(item);
        } else {
          temp = item;
        }
        result.push(temp);
      }
    } else {
      if (!(value instanceof this.modelType)) {
        result = new this.modelType();
        result.fill(value);
      } else {
        result = value;
      }
    }
    return result;
  }
  /**
   * @param {?} value
   * @return {?}
   */
  unmask(value) {
    if (!value) {
      return value;
    }
    /** @type {?} */
    let result = null;
    if (Array.isArray(value)) {
      result = [];
      for (const item of value) {
        if (!item) {
          continue;
        }
        if (item instanceof JsonApiNestedModel) {
          item.nestedDataSerialization = true;
          result.push(item.serialize());
          item.nestedDataSerialization = false;
        } else {
          result.push(item);
        }
      }
    } else {
      if (value instanceof JsonApiNestedModel) {
        value.nestedDataSerialization = true;
        result = value.serialize();
        value.nestedDataSerialization = false;
      } else {
        result = value;
      }
    }
    return result;
  }
}
if (false) {
  /**
   * @type {?}
   * @private
   */
  JsonModelConverter.prototype.modelType;
  /**
   * @type {?}
   * @private
   */
  JsonModelConverter.prototype.options;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?=} config
 * @return {?}
 */
function HasMany(config = {}) {
  return (
    /**
    * @param {?} target
    * @param {?} propertyName
    * @return {?}
    */
    (target, propertyName) => {
      /** @type {?} */
      const annotations = Reflect.getMetadata('HasMany', target) || [];
      annotations.push({
        propertyName,
        relationship: config.key || propertyName
      });
      Reflect.defineMetadata('HasMany', annotations, target);
    }
  );
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?=} config
 * @return {?}
 */
function BelongsTo(config = {}) {
  return (
    /**
    * @param {?} target
    * @param {?} propertyName
    * @return {?}
    */
    (target, propertyName) => {
      /** @type {?} */
      const annotations = Reflect.getMetadata('BelongsTo', target) || [];
      annotations.push({
        propertyName,
        relationship: config.key || propertyName
      });
      Reflect.defineMetadata('BelongsTo', annotations, target);
    }
  );
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
// tslint:disable-next-line:variable-name
/** @type {?} */
const AttributeMetadata = /** @type {?} */Symbol('AttributeMetadata');

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
class DateConverter {
  /**
   * @param {?} value
   * @return {?}
   */
  mask(value) {
    if (typeof value === 'string') {
      return parseISO(value);
    } else {
      return value;
    }
  }
  /**
   * @param {?} value
   * @return {?}
   */
  unmask(value) {
    return value ? value.toISOString() : value;
  }
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?=} options
 * @return {?}
 */
function Attribute(options = {}) {
  return (
    /**
    * @param {?} target
    * @param {?} propertyName
    * @return {?}
    */
    (target, propertyName) => {
      /** @type {?} */
      const converter =
      /**
      * @param {?} dataType
      * @param {?} value
      * @param {?=} forSerialisation
      * @return {?}
      */
      (dataType, value, forSerialisation = false) => {
        /** @type {?} */
        let attrConverter;
        if (options.converter) {
          attrConverter = options.converter;
        } else if (dataType === Date) {
          attrConverter = new DateConverter();
        } else {
          /** @type {?} */
          const datatype = new dataType();
          if (datatype.mask && datatype.unmask) {
            attrConverter = datatype;
          }
        }
        if (attrConverter) {
          if (!forSerialisation) {
            return attrConverter.mask(value);
          }
          return attrConverter.unmask(value);
        }
        return value;
      };
      /** @type {?} */
      const saveAnnotations =
      /**
      * @return {?}
      */
      () => {
        /** @type {?} */
        const metadata = Reflect.getMetadata('Attribute', target) || {};
        metadata[propertyName] = {
          marked: true
        };
        Reflect.defineMetadata('Attribute', metadata, target);
        /** @type {?} */
        const mappingMetadata = Reflect.getMetadata('AttributeMapping', target) || {};
        /** @type {?} */
        const serializedPropertyName = options.serializedName !== undefined ? options.serializedName : propertyName;
        mappingMetadata[serializedPropertyName] = propertyName;
        Reflect.defineMetadata('AttributeMapping', mappingMetadata, target);
      };
      /** @type {?} */
      const setMetadata =
      /**
      * @param {?} instance
      * @param {?} oldValue
      * @param {?} newValue
      * @return {?}
      */
      (instance, oldValue, newValue) => {
        /** @type {?} */
        const targetType = Reflect.getMetadata('design:type', target, propertyName);
        if (!instance[AttributeMetadata]) {
          instance[AttributeMetadata] = {};
        }
        instance[AttributeMetadata][propertyName] = {
          newValue,
          oldValue,
          nested: false,
          serializedName: options.serializedName,
          hasDirtyAttributes: !isEqual(oldValue, newValue),
          serialisationValue: converter(targetType, newValue, true)
        };
      };
      /** @type {?} */
      const getter =
      /**
      * @return {?}
      */
      function () {
        return this[`_${propertyName}`];
      };
      /** @type {?} */
      const setter =
      /**
      * @param {?} newVal
      * @return {?}
      */
      function (newVal) {
        /** @type {?} */
        const targetType = Reflect.getMetadata('design:type', target, propertyName);
        /** @type {?} */
        const convertedValue = converter(targetType, newVal);
        /** @type {?} */
        let oldValue = null;
        if (this.isModelInitialization() && this.id) {
          oldValue = converter(targetType, newVal);
        } else {
          if (this[AttributeMetadata] && this[AttributeMetadata][propertyName]) {
            oldValue = this[AttributeMetadata][propertyName].oldValue;
          }
        }
        this[`_${propertyName}`] = convertedValue;
        setMetadata(this, oldValue, convertedValue);
      };
      if (delete target[propertyName]) {
        saveAnnotations();
        Object.defineProperty(target, propertyName, {
          get: getter,
          set: setter,
          enumerable: true,
          configurable: true
        });
      }
    }
  );
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?=} options
 * @return {?}
 */
function NestedAttribute(options = {}) {
  return (
    /**
    * @param {?} target
    * @param {?} propertyName
    * @return {?}
    */
    (target, propertyName) => {
      /** @type {?} */
      const converter =
      /**
      * @param {?} dataType
      * @param {?} value
      * @param {?=} forSerialisation
      * @return {?}
      */
      (dataType, value, forSerialisation = false) => {
        /** @type {?} */
        let attrConverter;
        if (options.converter) {
          attrConverter = options.converter;
        } else {
          /** @type {?} */
          const datatype = new dataType();
          if (datatype.mask && datatype.unmask) {
            attrConverter = datatype;
          }
        }
        if (attrConverter) {
          if (!forSerialisation) {
            return attrConverter.mask(value);
          }
          return attrConverter.unmask(value);
        }
        return value;
      };
      /** @type {?} */
      const saveAnnotations =
      /**
      * @return {?}
      */
      () => {
        /** @type {?} */
        const metadata = Reflect.getMetadata('NestedAttribute', target) || {};
        metadata[propertyName] = {
          marked: true
        };
        Reflect.defineMetadata('NestedAttribute', metadata, target);
        /** @type {?} */
        const mappingMetadata = Reflect.getMetadata('AttributeMapping', target) || {};
        /** @type {?} */
        const serializedPropertyName = options.serializedName !== undefined ? options.serializedName : propertyName;
        mappingMetadata[serializedPropertyName] = propertyName;
        Reflect.defineMetadata('AttributeMapping', mappingMetadata, target);
      };
      /** @type {?} */
      const updateMetadata =
      /**
      * @param {?} instance
      * @return {?}
      */
      instance => {
        /** @type {?} */
        const newValue = instance[`_${propertyName}`];
        if (!instance[AttributeMetadata]) {
          instance[AttributeMetadata] = {};
        }
        if (instance[AttributeMetadata][propertyName] && !instance.isModelInitialization()) {
          instance[AttributeMetadata][propertyName].newValue = newValue;
          instance[AttributeMetadata][propertyName].hasDirtyAttributes = !isEqual(instance[AttributeMetadata][propertyName].oldValue, newValue);
          instance[AttributeMetadata][propertyName].serialisationValue = newValue;
        } else {
          /** @type {?} */
          const oldValue = cloneDeep(newValue);
          instance[AttributeMetadata][propertyName] = {
            newValue,
            oldValue,
            converter,
            nested: true,
            hasDirtyAttributes: !isEqual(newValue, oldValue)
          };
        }
      };
      /** @type {?} */
      const getter =
      /**
      * @return {?}
      */
      function () {
        return this[`_${propertyName}`];
      };
      /** @type {?} */
      const setter =
      /**
      * @param {?} newVal
      * @return {?}
      */
      function (newVal) {
        /** @type {?} */
        const targetType = Reflect.getMetadata('design:type', target, propertyName);
        this[`_${propertyName}`] = converter(targetType, newVal);
        updateMetadata(this);
      };
      if (delete target[propertyName]) {
        saveAnnotations();
        Object.defineProperty(target, propertyName, {
          get: getter,
          set: setter,
          enumerable: true,
          configurable: true
        });
      }
    }
  );
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?=} options
 * @return {?}
 */
function JsonAttribute(options = {}) {
  return (
    /**
    * @param {?} target
    * @param {?} propertyName
    * @return {?}
    */
    (target, propertyName) => {
      /** @type {?} */
      const converter =
      /**
      * @param {?} dataType
      * @param {?} value
      * @param {?=} forSerialisation
      * @return {?}
      */
      (dataType, value, forSerialisation = false) => {
        /** @type {?} */
        let attrConverter;
        if (options.converter) {
          attrConverter = options.converter;
        } else if (dataType === Date) {
          attrConverter = new DateConverter();
        } else {
          /** @type {?} */
          const datatype = new dataType();
          if (datatype.mask && datatype.unmask) {
            attrConverter = datatype;
          }
        }
        if (attrConverter) {
          if (!forSerialisation) {
            return attrConverter.mask(value);
          }
          return attrConverter.unmask(value);
        }
        return value;
      };
      /** @type {?} */
      const saveAnnotations =
      /**
      * @return {?}
      */
      () => {
        /** @type {?} */
        const metadata = Reflect.getMetadata('JsonAttribute', target) || {};
        metadata[propertyName] = {
          marked: true
        };
        Reflect.defineMetadata('JsonAttribute', metadata, target);
        /** @type {?} */
        const mappingMetadata = Reflect.getMetadata('AttributeMapping', target) || {};
        /** @type {?} */
        const serializedPropertyName = options.serializedName !== undefined ? options.serializedName : propertyName;
        mappingMetadata[serializedPropertyName] = propertyName;
        Reflect.defineMetadata('AttributeMapping', mappingMetadata, target);
      };
      /** @type {?} */
      const getter =
      /**
      * @return {?}
      */
      function () {
        if (this.nestedDataSerialization) {
          return converter(Reflect.getMetadata('design:type', target, propertyName), this[`_${propertyName}`], true);
        }
        return this[`_${propertyName}`];
      };
      /** @type {?} */
      const setter =
      /**
      * @param {?} newVal
      * @return {?}
      */
      function (newVal) {
        /** @type {?} */
        const targetType = Reflect.getMetadata('design:type', target, propertyName);
        this[`_${propertyName}`] = converter(targetType, newVal);
      };
      if (delete target[propertyName]) {
        saveAnnotations();
        Object.defineProperty(target, propertyName, {
          get: getter,
          set: setter,
          enumerable: true,
          configurable: true
        });
      }
    }
  );
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
class JsonApiMetaModel {
  /**
   * @param {?} response
   */
  constructor(response) {
    this.links = response.links || [];
    this.meta = response.meta;
  }
}
if (false) {
  /** @type {?} */
  JsonApiMetaModel.prototype.links;
  /** @type {?} */
  JsonApiMetaModel.prototype.meta;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} config
 * @return {?}
 */
function JsonApiModelConfig(config) {
  return (
    /**
    * @param {?} target
    * @return {?}
    */
    target => {
      if (typeof config.meta === 'undefined' || config.meta == null) {
        config.meta = JsonApiMetaModel;
      }
      Reflect.defineMetadata('JsonApiModelConfig', config, target);
    }
  );
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?=} config
 * @return {?}
 */
function JsonApiDatastoreConfig(config = {}) {
  return (
    /**
    * @param {?} target
    * @return {?}
    */
    target => {
      Reflect.defineMetadata('JsonApiDatastoreConfig', config, target);
    }
  );
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
// tslint:disable-next-line:variable-name
/**
 * HACK/FIXME:
 * Type 'symbol' cannot be used as an index type.
 * TypeScript 2.9.x
 * See https://github.com/Microsoft/TypeScript/issues/24587.
 * @type {?}
 */
const AttributeMetadataIndex = /** @type {?} */AttributeMetadata;
/** @type {?} */
const parseRelationshipLinks =
/**
* @param {?} relationships
* @return {?}
*/
relationships => {
  /** @type {?} */
  const result = {};
  /** @type {?} */
  const linksMapper =
  /**
  * @param {?} __0
  * @param {?} key
  * @return {?}
  */
  ({
    links
  }, key) => {
    if (links) {
      result[key] = {
        links
      };
    }
  };
  forEach(relationships || {}, linksMapper);
  return result;
};
const ɵ0 = parseRelationshipLinks;
class JsonApiModel {
  /**
   * @param {?} internalDatastore
   * @param {?=} data
   */
  constructor(internalDatastore, data) {
    this.internalDatastore = internalDatastore;
    this.modelInitialization = false;
    this.relationshipLinks = {};
    this.unresolvedRelations = {};
    if (data) {
      this.modelInitialization = true;
      this.id = data.id;
      this.relationshipLinks = parseRelationshipLinks(data.relationships);
      Object.assign(this, data.attributes);
      this.modelInitialization = false;
    }
  }
  /**
   * @return {?}
   */
  isModelInitialization() {
    return this.modelInitialization;
  }
  /**
   * @param {?} data
   * @param {?} included
   * @param {?=} remainingModels
   * @return {?}
   */
  syncRelationships(data, included, remainingModels) {
    if (this.lastSyncModels === included) {
      return;
    }
    if (data) {
      /** @type {?} */
      let modelsForProcessing = remainingModels;
      if (modelsForProcessing === undefined) {
        modelsForProcessing = [].concat(included);
      }
      this.parseHasMany(data, included, modelsForProcessing);
      this.parseBelongsTo(data, included, modelsForProcessing);
    }
    this.lastSyncModels = included;
  }
  /**
   * @param {?=} params
   * @param {?=} headers
   * @param {?=} customUrl
   * @return {?}
   */
  save(params, headers, customUrl) {
    this.checkChanges();
    /** @type {?} */
    const attributesMetadata = this[AttributeMetadataIndex];
    return this.internalDatastore.saveRecord(attributesMetadata, this, params, headers, customUrl);
  }
  /**
   * @return {?}
   */
  get hasDirtyAttributes() {
    this.checkChanges();
    /** @type {?} */
    const attributesMetadata = this[AttributeMetadataIndex];
    /** @type {?} */
    let hasDirtyAttributes = false;
    for (const propertyName in attributesMetadata) {
      if (attributesMetadata.hasOwnProperty(propertyName)) {
        /** @type {?} */
        const metadata = attributesMetadata[propertyName];
        if (metadata.hasDirtyAttributes) {
          hasDirtyAttributes = true;
          break;
        }
      }
    }
    return hasDirtyAttributes;
  }
  /**
   * @private
   * @return {?}
   */
  checkChanges() {
    /** @type {?} */
    const attributesMetadata = this[AttributeMetadata];
    for (const propertyName in attributesMetadata) {
      if (attributesMetadata.hasOwnProperty(propertyName)) {
        /** @type {?} */
        const metadata = attributesMetadata[propertyName];
        if (metadata.nested) {
          this[AttributeMetadata][propertyName].hasDirtyAttributes = !isEqual(attributesMetadata[propertyName].oldValue, attributesMetadata[propertyName].newValue);
          this[AttributeMetadata][propertyName].serialisationValue = attributesMetadata[propertyName].converter(Reflect.getMetadata('design:type', this, propertyName), cloneDeep(attributesMetadata[propertyName].newValue), true);
        }
      }
    }
  }
  /**
   * @return {?}
   */
  rollbackAttributes() {
    /** @type {?} */
    const attributesMetadata = this[AttributeMetadataIndex];
    for (const propertyName in attributesMetadata) {
      if (attributesMetadata.hasOwnProperty(propertyName)) {
        if (attributesMetadata[propertyName].hasDirtyAttributes) {
          this[propertyName] = cloneDeep(attributesMetadata[propertyName].oldValue);
        }
      }
    }
  }
  /**
   * @return {?}
   */
  get modelConfig() {
    return Reflect.getMetadata('JsonApiModelConfig', this.constructor);
  }
  /**
   * @private
   * @param {?} data
   * @param {?} included
   * @param {?} remainingModels
   * @return {?}
   */
  parseHasMany(data, included, remainingModels) {
    /** @type {?} */
    const hasMany = Reflect.getMetadata('HasMany', this);
    if (hasMany) {
      for (const metadata of hasMany) {
        /** @type {?} */
        const relationship = data.relationships ? data.relationships[metadata.relationship] : null;
        if (relationship && relationship.data && Array.isArray(relationship.data)) {
          /** @type {?} */
          let allModels = [];
          /** @type {?} */
          const modelTypesFetched = [];
          for (const typeIndex of Object.keys(relationship.data)) {
            /** @type {?} */
            const typeName = relationship.data[typeIndex].type;
            if (!includes(modelTypesFetched, typeName)) {
              modelTypesFetched.push(typeName);
              // tslint:disable-next-line:max-line-length
              /** @type {?} */
              const modelType = Reflect.getMetadata('JsonApiDatastoreConfig', this.internalDatastore.constructor).models[typeName];
              if (modelType) {
                /** @type {?} */
                const relationshipModels = this.getHasManyRelationship(modelType, relationship.data, included, typeName, remainingModels);
                if (relationshipModels.length > 0) {
                  allModels = allModels.concat(relationshipModels);
                }
              } else {
                console.error(`parseHasMany - Model type for relationship ${typeName} not found.`);
              }
            }
          }
          this[metadata.propertyName] = allModels;
        }
      }
    }
  }
  /**
   * @private
   * @param {?} data
   * @param {?} included
   * @param {?} remainingModels
   * @return {?}
   */
  parseBelongsTo(data, included, remainingModels) {
    /** @type {?} */
    const belongsTo = Reflect.getMetadata('BelongsTo', this);
    if (belongsTo) {
      for (const metadata of belongsTo) {
        /** @type {?} */
        const relationship = data.relationships ? data.relationships[metadata.relationship] : null;
        if (relationship && relationship.data) {
          /** @type {?} */
          const dataRelationship = relationship.data instanceof Array ? relationship.data[0] : relationship.data;
          if (dataRelationship) {
            /** @type {?} */
            const typeName = dataRelationship.type;
            // tslint:disable-next-line:max-line-length
            /** @type {?} */
            const modelType = Reflect.getMetadata('JsonApiDatastoreConfig', this.internalDatastore.constructor).models[typeName];
            if (modelType) {
              /** @type {?} */
              const relationshipModel = this.getBelongsToRelationship(modelType, dataRelationship, included, typeName, remainingModels);
              if (relationshipModel) {
                this[metadata.propertyName] = relationshipModel;
              } else {
                this.unresolvedRelations[metadata.propertyName] = dataRelationship;
              }
            } else {
              console.error(`parseBelongsTo - Model type for relationship ${typeName} not found.`);
            }
          }
        }
      }
    }
  }
  /**
   * @private
   * @template T
   * @param {?} modelType
   * @param {?} data
   * @param {?} included
   * @param {?} typeName
   * @param {?} remainingModels
   * @return {?}
   */
  getHasManyRelationship(modelType, data, included, typeName, remainingModels) {
    /** @type {?} */
    const relationshipList = [];
    data.forEach(
    /**
    * @param {?} item
    * @return {?}
    */
    item => {
      /** @type {?} */
      const relationshipData = find(included, /** @type {?} */{
        id: item.id,
        type: typeName
      });
      if (relationshipData) {
        /** @type {?} */
        const newObject = this.createOrPeek(modelType, relationshipData);
        /** @type {?} */
        const indexOfNewlyFoundModel = remainingModels.indexOf(relationshipData);
        /** @type {?} */
        const modelsForProcessing = remainingModels.concat([]);
        if (indexOfNewlyFoundModel !== -1) {
          modelsForProcessing.splice(indexOfNewlyFoundModel, 1);
          newObject.syncRelationships(relationshipData, included, modelsForProcessing);
        }
        relationshipList.push(newObject);
      } else {
        /** @type {?} */
        const type = Reflect.getMetadata('JsonApiDatastoreConfig', this.internalDatastore.constructor).models[typeName];
        /** @type {?} */
        const newObject = this.internalDatastore.peekRecord(type, item.id);
        if (newObject) {
          relationshipList.push(newObject);
        }
      }
    });
    return relationshipList;
  }
  /**
   * @private
   * @template T
   * @param {?} modelType
   * @param {?} data
   * @param {?} included
   * @param {?} typeName
   * @param {?} remainingModels
   * @return {?}
   */
  getBelongsToRelationship(modelType, data, included, typeName, remainingModels) {
    /** @type {?} */
    const id = data.id;
    /** @type {?} */
    const relationshipData = find(included, /** @type {?} */{
      id,
      type: typeName
    });
    if (relationshipData) {
      /** @type {?} */
      const newObject = this.createOrPeek(modelType, relationshipData);
      /** @type {?} */
      const indexOfNewlyFoundModel = remainingModels.indexOf(relationshipData);
      /** @type {?} */
      const modelsForProcessing = remainingModels.concat([]);
      if (indexOfNewlyFoundModel !== -1) {
        modelsForProcessing.splice(indexOfNewlyFoundModel, 1);
        newObject.syncRelationships(relationshipData, included, modelsForProcessing);
      }
      return newObject;
    }
    return this.internalDatastore.peekRecord(modelType, id);
  }
  /**
   * @private
   * @template T
   * @param {?} modelType
   * @param {?} data
   * @return {?}
   */
  createOrPeek(modelType, data) {
    /** @type {?} */
    const peek = this.internalDatastore.peekRecord(modelType, data.id);
    if (peek) {
      extend(peek, this.internalDatastore.transformSerializedNamesToPropertyNames(modelType, data.attributes));
      return peek;
    }
    /** @type {?} */
    const newObject = this.internalDatastore.deserializeModel(modelType, data);
    this.internalDatastore.addToStore(newObject);
    return newObject;
  }
}
if (false) {
  /** @type {?} */
  JsonApiModel.prototype.id;
  /** @type {?} */
  JsonApiModel.prototype.modelInitialization;
  /** @type {?} */
  JsonApiModel.prototype.relationshipLinks;
  /** @type {?} */
  JsonApiModel.prototype.unresolvedRelations;
  /** @type {?} */
  JsonApiModel.prototype.lastSyncModels;
  /**
   * @type {?}
   * @private
   */
  JsonApiModel.prototype.internalDatastore;
  /* Skipping unhandled member: [key: string]: any;*/
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @record
 */
function JsonApiError() {}
if (false) {
  /** @type {?|undefined} */
  JsonApiError.prototype.id;
  /** @type {?|undefined} */
  JsonApiError.prototype.links;
  /** @type {?|undefined} */
  JsonApiError.prototype.status;
  /** @type {?|undefined} */
  JsonApiError.prototype.code;
  /** @type {?|undefined} */
  JsonApiError.prototype.title;
  /** @type {?|undefined} */
  JsonApiError.prototype.detail;
  /** @type {?|undefined} */
  JsonApiError.prototype.source;
  /** @type {?|undefined} */
  JsonApiError.prototype.meta;
}
class ErrorResponse {
  /**
   * @param {?=} errors
   */
  constructor(errors) {
    this.errors = [];
    if (errors) {
      this.errors = errors;
    }
  }
}
if (false) {
  /** @type {?} */
  ErrorResponse.prototype.errors;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @template T
 */
class JsonApiQueryData {
  /**
   * @param {?} jsonApiModels
   * @param {?=} metaData
   */
  constructor(jsonApiModels, metaData) {
    this.jsonApiModels = jsonApiModels;
    this.metaData = metaData;
  }
  /**
   * @return {?}
   */
  getModels() {
    return this.jsonApiModels;
  }
  /**
   * @return {?}
   */
  getMeta() {
    return this.metaData;
  }
}
if (false) {
  /**
   * @type {?}
   * @protected
   */
  JsonApiQueryData.prototype.jsonApiModels;
  /**
   * @type {?}
   * @protected
   */
  JsonApiQueryData.prototype.metaData;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @record
 */
function Overrides() {}
if (false) {
  /** @type {?|undefined} */
  Overrides.prototype.getDirtyAttributes;
  /** @type {?|undefined} */
  Overrides.prototype.toQueryString;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @record
 */
function JsonModelConverterConfig() {}
if (false) {
  /** @type {?|undefined} */
  JsonModelConverterConfig.prototype.nullValue;
  /** @type {?|undefined} */
  JsonModelConverterConfig.prototype.hasMany;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @record
 */
function DatastoreConfig() {}
if (false) {
  /** @type {?|undefined} */
  DatastoreConfig.prototype.apiVersion;
  /** @type {?|undefined} */
  DatastoreConfig.prototype.baseUrl;
  /** @type {?|undefined} */
  DatastoreConfig.prototype.models;
  /** @type {?|undefined} */
  DatastoreConfig.prototype.overrides;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @record
 * @template T
 */
function ModelConfig() {}
if (false) {
  /** @type {?} */
  ModelConfig.prototype.type;
  /** @type {?|undefined} */
  ModelConfig.prototype.apiVersion;
  /** @type {?|undefined} */
  ModelConfig.prototype.baseUrl;
  /** @type {?|undefined} */
  ModelConfig.prototype.modelEndpointUrl;
  /** @type {?|undefined} */
  ModelConfig.prototype.meta;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @record
 */
function AttributeDecoratorOptions() {}
if (false) {
  /** @type {?|undefined} */
  AttributeDecoratorOptions.prototype.serializedName;
  /** @type {?|undefined} */
  AttributeDecoratorOptions.prototype.converter;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @record
 */
function PropertyConverter() {}
if (false) {
  /**
   * @param {?} value
   * @return {?}
   */
  PropertyConverter.prototype.mask = function (value) {};
  /**
   * @param {?} value
   * @return {?}
   */
  PropertyConverter.prototype.unmask = function (value) {};
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
// tslint:disable-next-line:variable-name
/**
 * HACK/FIXME:
 * Type 'symbol' cannot be used as an index type.
 * TypeScript 2.9.x
 * See https://github.com/Microsoft/TypeScript/issues/24587.
 * @type {?}
 */
const AttributeMetadataIndex$1 = /** @type {?} */AttributeMetadata;
class JsonApiDatastore {
  /**
   * @param {?} http
   */
  constructor(http) {
    this.http = http;
    this.globalRequestOptions = {};
    this.internalStore = {};
    this.toQueryString = this.datastoreConfig.overrides && this.datastoreConfig.overrides.toQueryString ? this.datastoreConfig.overrides.toQueryString : this._toQueryString;
  }
  /**
   * @param {?} headers
   * @return {?}
   */
  set headers(headers) {
    this.globalHeaders = headers;
  }
  /**
   * @param {?} requestOptions
   * @return {?}
   */
  set requestOptions(requestOptions) {
    this.globalRequestOptions = requestOptions;
  }
  /**
   * @return {?}
   */
  get datastoreConfig() {
    /** @type {?} */
    const configFromDecorator = Reflect.getMetadata('JsonApiDatastoreConfig', this.constructor);
    return Object.assign(configFromDecorator, this.config);
  }
  /**
   * @private
   * @return {?}
   */
  get getDirtyAttributes() {
    if (this.datastoreConfig.overrides && this.datastoreConfig.overrides.getDirtyAttributes) {
      return this.datastoreConfig.overrides.getDirtyAttributes;
    }
    return JsonApiDatastore.getDirtyAttributes;
  }
  /**
   * @private
   * @param {?} attributesMetadata
   * @return {?}
   */
  static getDirtyAttributes(attributesMetadata) {
    /** @type {?} */
    const dirtyData = {};
    for (const propertyName in attributesMetadata) {
      if (attributesMetadata.hasOwnProperty(propertyName)) {
        /** @type {?} */
        const metadata = attributesMetadata[propertyName];
        if (metadata.hasDirtyAttributes) {
          /** @type {?} */
          const attributeName = metadata.serializedName != null ? metadata.serializedName : propertyName;
          dirtyData[attributeName] = metadata.serialisationValue ? metadata.serialisationValue : metadata.newValue;
        }
      }
    }
    return dirtyData;
  }
  /**
   * @deprecated use findAll method to take all models
   * @template T
   * @param {?} modelType
   * @param {?=} params
   * @param {?=} headers
   * @param {?=} customUrl
   * @return {?}
   */
  query(modelType, params, headers, customUrl) {
    /** @type {?} */
    const requestHeaders = this.buildHttpHeaders(headers);
    /** @type {?} */
    const url = this.buildUrl(modelType, params, undefined, customUrl);
    return this.http.get(url, {
      headers: requestHeaders
    }).pipe(map(
    /**
    * @param {?} res
    * @return {?}
    */
    res => this.extractQueryData(res, modelType)), catchError(
    /**
    * @param {?} res
    * @return {?}
    */
    res => this.handleError(res)));
  }
  /**
   * @template T
   * @param {?} modelType
   * @param {?=} params
   * @param {?=} headers
   * @param {?=} customUrl
   * @return {?}
   */
  findAll(modelType, params, headers, customUrl) {
    /** @type {?} */
    const url = this.buildUrl(modelType, params, undefined, customUrl);
    /** @type {?} */
    const requestOptions = this.buildRequestOptions({
      headers,
      observe: 'response'
    });
    return this.http.get(url, requestOptions).pipe(map(
    /**
    * @param {?} res
    * @return {?}
    */
    res => this.extractQueryData(res, modelType, true)), catchError(
    /**
    * @param {?} res
    * @return {?}
    */
    res => this.handleError(res)));
  }
  /**
   * @template T
   * @param {?} modelType
   * @param {?} id
   * @param {?=} params
   * @param {?=} headers
   * @param {?=} customUrl
   * @return {?}
   */
  findRecord(modelType, id, params, headers, customUrl) {
    /** @type {?} */
    const requestOptions = this.buildRequestOptions({
      headers,
      observe: 'response'
    });
    /** @type {?} */
    const url = this.buildUrl(modelType, params, id, customUrl);
    return this.http.get(url, requestOptions).pipe(map(
    /**
    * @param {?} res
    * @return {?}
    */
    res => this.extractRecordData(res, modelType)), catchError(
    /**
    * @param {?} res
    * @return {?}
    */
    res => this.handleError(res)));
  }
  /**
   * @template T
   * @param {?} modelType
   * @param {?=} data
   * @return {?}
   */
  createRecord(modelType, data) {
    return new modelType(this, {
      attributes: data
    });
  }
  /**
   * @template T
   * @param {?} attributesMetadata
   * @param {?} model
   * @param {?=} params
   * @param {?=} headers
   * @param {?=} customUrl
   * @return {?}
   */
  saveRecord(attributesMetadata, model, params, headers, customUrl) {
    /** @type {?} */
    const modelType = /** @type {?} */model.constructor;
    /** @type {?} */
    const modelConfig = model.modelConfig;
    /** @type {?} */
    const typeName = modelConfig.type;
    /** @type {?} */
    const relationships = this.getRelationships(model);
    /** @type {?} */
    const url = this.buildUrl(modelType, params, model.id, customUrl);
    /** @type {?} */
    let httpCall;
    /** @type {?} */
    const body = {
      data: {
        relationships,
        type: typeName,
        id: model.id,
        attributes: this.getDirtyAttributes(attributesMetadata, model)
      }
    };
    /** @type {?} */
    const requestOptions = this.buildRequestOptions({
      headers,
      observe: 'response'
    });
    if (model.id) {
      httpCall = /** @type {?} */this.http.patch(url, body, requestOptions);
    } else {
      httpCall = /** @type {?} */this.http.post(url, body, requestOptions);
    }
    return httpCall.pipe(map(
    /**
    * @param {?} res
    * @return {?}
    */
    res => [200, 201].indexOf(res.status) !== -1 ? this.extractRecordData(res, modelType, model) : model), catchError(
    /**
    * @param {?} res
    * @return {?}
    */
    res => {
      if (res == null) {
        return of(model);
      }
      return this.handleError(res);
    }), map(
    /**
    * @param {?} res
    * @return {?}
    */
    res => this.updateRelationships(res, relationships)));
  }
  /**
   * @template T
   * @param {?} modelType
   * @param {?} id
   * @param {?=} headers
   * @param {?=} customUrl
   * @return {?}
   */
  deleteRecord(modelType, id, headers, customUrl) {
    /** @type {?} */
    const requestOptions = this.buildRequestOptions({
      headers
    });
    /** @type {?} */
    const url = this.buildUrl(modelType, null, id, customUrl);
    return this.http.delete(url, requestOptions).pipe(catchError(
    /**
    * @param {?} res
    * @return {?}
    */
    res => this.handleError(res)));
  }
  /**
   * @template T
   * @param {?} modelType
   * @param {?} id
   * @return {?}
   */
  peekRecord(modelType, id) {
    /** @type {?} */
    const type = Reflect.getMetadata('JsonApiModelConfig', modelType).type;
    return this.internalStore[type] ? (/** @type {?} */this.internalStore[type][id]) : null;
  }
  /**
   * @template T
   * @param {?} modelType
   * @return {?}
   */
  peekAll(modelType) {
    /** @type {?} */
    const type = Reflect.getMetadata('JsonApiModelConfig', modelType).type;
    /** @type {?} */
    const typeStore = this.internalStore[type];
    return typeStore ? Object.keys(typeStore).map(
    /**
    * @param {?} key
    * @return {?}
    */
    key => (/** @type {?} */typeStore[key])) : [];
  }
  /**
   * @template T
   * @param {?} modelType
   * @param {?} data
   * @return {?}
   */
  deserializeModel(modelType, data) {
    data.attributes = this.transformSerializedNamesToPropertyNames(modelType, data.attributes);
    return new modelType(this, data);
  }
  /**
   * @param {?} modelOrModels
   * @return {?}
   */
  addToStore(modelOrModels) {
    /** @type {?} */
    const models = Array.isArray(modelOrModels) ? modelOrModels : [modelOrModels];
    /** @type {?} */
    const type = models[0].modelConfig.type;
    /** @type {?} */
    let typeStore = this.internalStore[type];
    if (!typeStore) {
      typeStore = this.internalStore[type] = {};
    }
    for (const model of models) {
      typeStore[model.id] = model;
    }
  }
  /**
   * @template T
   * @param {?} modelType
   * @param {?} attributes
   * @return {?}
   */
  transformSerializedNamesToPropertyNames(modelType, attributes) {
    /** @type {?} */
    const serializedNameToPropertyName = this.getModelPropertyNames(modelType.prototype);
    /** @type {?} */
    const properties = {};
    Object.keys(serializedNameToPropertyName).forEach(
    /**
    * @param {?} serializedName
    * @return {?}
    */
    serializedName => {
      if (attributes) {
        properties[serializedNameToPropertyName[serializedName]] = attributes[serializedName];
      }
    });
    return properties;
  }
  /**
   * @protected
   * @template T
   * @param {?} modelType
   * @param {?=} params
   * @param {?=} id
   * @param {?=} customUrl
   * @return {?}
   */
  buildUrl(modelType, params, id, customUrl) {
    // TODO: use HttpParams instead of appending a string to the url
    /** @type {?} */
    const queryParams = this.toQueryString(params);
    if (customUrl) {
      return queryParams ? `${customUrl}?${queryParams}` : customUrl;
    }
    /** @type {?} */
    const modelConfig = Reflect.getMetadata('JsonApiModelConfig', modelType);
    /** @type {?} */
    const baseUrl = modelConfig.baseUrl || this.datastoreConfig.baseUrl;
    /** @type {?} */
    const apiVersion = modelConfig.apiVersion || this.datastoreConfig.apiVersion;
    /** @type {?} */
    const modelEndpointUrl = modelConfig.modelEndpointUrl || modelConfig.type;
    /** @type {?} */
    const url = [baseUrl, apiVersion, modelEndpointUrl, id].filter(
    /**
    * @param {?} x
    * @return {?}
    */
    x => x).join('/');
    return queryParams ? `${url}?${queryParams}` : url;
  }
  /**
   * @protected
   * @param {?} data
   * @return {?}
   */
  getRelationships(data) {
    /** @type {?} */
    let relationships;
    /** @type {?} */
    const belongsToMetadata = Reflect.getMetadata('BelongsTo', data) || [];
    /** @type {?} */
    const hasManyMetadata = Reflect.getMetadata('HasMany', data) || [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] instanceof JsonApiModel) {
          relationships = relationships || {};
          if (data[key].id) {
            /** @type {?} */
            const entity = belongsToMetadata.find(
            /**
            * @param {?} it
            * @return {?}
            */
            it => it.propertyName === key);
            /** @type {?} */
            const relationshipKey = entity.relationship;
            relationships[relationshipKey] = {
              data: this.buildSingleRelationshipData(data[key])
            };
          }
        } else if (data[key] instanceof Array) {
          /** @type {?} */
          const entity = hasManyMetadata.find(
          /**
          * @param {?} it
          * @return {?}
          */
          it => it.propertyName === key);
          if (entity && this.isValidToManyRelation(data[key])) {
            relationships = relationships || {};
            /** @type {?} */
            const relationshipKey = entity.relationship;
            /** @type {?} */
            const relationshipData = data[key].filter(
            /**
            * @param {?} model
            * @return {?}
            */
            model => model.id).map(
            /**
            * @param {?} model
            * @return {?}
            */
            model => this.buildSingleRelationshipData(model));
            relationships[relationshipKey] = {
              data: relationshipData
            };
          }
        }
      }
    }
    return relationships;
  }
  /**
   * @protected
   * @param {?} objects
   * @return {?}
   */
  isValidToManyRelation(objects) {
    if (!objects.length) {
      return true;
    }
    /** @type {?} */
    const isJsonApiModel = objects.every(
    /**
    * @param {?} item
    * @return {?}
    */
    item => item instanceof JsonApiModel);
    if (!isJsonApiModel) {
      return false;
    }
    /** @type {?} */
    const types = objects.map(
    /**
    * @param {?} item
    * @return {?}
    */
    item => item.modelConfig.modelEndpointUrl || item.modelConfig.type);
    return types.filter(
    /**
    * @param {?} type
    * @param {?} index
    * @param {?} self
    * @return {?}
    */
    (type, index, self) => self.indexOf(type) === index).length === 1;
  }
  /**
   * @protected
   * @param {?} model
   * @return {?}
   */
  buildSingleRelationshipData(model) {
    /** @type {?} */
    const relationshipType = model.modelConfig.type;
    /** @type {?} */
    const relationShipData = {
      type: relationshipType
    };
    if (model.id) {
      relationShipData.id = model.id;
    } else {
      /** @type {?} */
      const attributesMetadata = Reflect.getMetadata('Attribute', model);
      relationShipData.attributes = this.getDirtyAttributes(attributesMetadata, model);
    }
    return relationShipData;
  }
  /**
   * @protected
   * @template T
   * @param {?} response
   * @param {?} modelType
   * @param {?=} withMeta
   * @return {?}
   */
  extractQueryData(response, modelType, withMeta = false) {
    /** @type {?} */
    const body = response.body;
    /** @type {?} */
    const models = [];
    body.data.forEach(
    /**
    * @param {?} data
    * @return {?}
    */
    data => {
      /** @type {?} */
      let type = this.datastoreConfig.models[data.type];
      type = type ? type : modelType;
      /** @type {?} */
      const model = this.deserializeModel(type, data);
      this.addToStore(model);
      if (body.included) {
        model.syncRelationships(data, body.included.concat(data));
        this.addToStore(model);
      }
      models.push(model);
    });
    if (withMeta && withMeta === true) {
      return new JsonApiQueryData(models, this.parseMeta(body, modelType));
    }
    return models;
  }
  /**
   * @protected
   * @template T
   * @param {?} res
   * @param {?} modelType
   * @param {?=} model
   * @return {?}
   */
  extractRecordData(res, modelType, model) {
    /** @type {?} */
    const body = res.body;
    // Error in Angular < 5.2.4 (see https://github.com/angular/angular/issues/20744)
    // null is converted to 'null', so this is temporary needed to make testcase possible
    // (and to avoid a decrease of the coverage)
    if (!body || body === 'null') {
      throw new Error('no body in response');
    }
    if (!body.data) {
      if (res.status === 201 || !model) {
        throw new Error('expected data in response');
      }
      return model;
    }
    if (model) {
      model.modelInitialization = true;
      model.id = body.data.id;
      Object.assign(model, body.data.attributes);
      model.modelInitialization = false;
    }
    /** @type {?} */
    const deserializedModel = model || this.deserializeModel(modelType, body.data);
    this.addToStore(deserializedModel);
    if (body.included) {
      deserializedModel.syncRelationships(body.data, body.included);
      this.addToStore(deserializedModel);
    }
    return deserializedModel;
  }
  /**
   * @protected
   * @param {?} error
   * @return {?}
   */
  handleError(error) {
    if (error instanceof HttpErrorResponse && error.error instanceof Object && error.error.errors && error.error.errors instanceof Array) {
      /** @type {?} */
      const errors = new ErrorResponse(error.error.errors);
      return throwError(errors);
    }
    return throwError(error);
  }
  /**
   * @protected
   * @param {?} body
   * @param {?} modelType
   * @return {?}
   */
  parseMeta(body, modelType) {
    /** @type {?} */
    const metaModel = Reflect.getMetadata('JsonApiModelConfig', modelType).meta;
    return new metaModel(body);
  }
  /**
   * @deprecated use buildHttpHeaders method to build request headers
   * @protected
   * @param {?=} customHeaders
   * @return {?}
   */
  getOptions(customHeaders) {
    return {
      headers: this.buildHttpHeaders(customHeaders)
    };
  }
  /**
   * @protected
   * @param {?=} customHeaders
   * @return {?}
   */
  buildHttpHeaders(customHeaders) {
    /** @type {?} */
    let requestHeaders = new HttpHeaders({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    });
    if (this.globalHeaders) {
      this.globalHeaders.keys().forEach(
      /**
      * @param {?} key
      * @return {?}
      */
      key => {
        if (this.globalHeaders.has(key)) {
          requestHeaders = requestHeaders.set(key, this.globalHeaders.get(key));
        }
      });
    }
    if (customHeaders) {
      customHeaders.keys().forEach(
      /**
      * @param {?} key
      * @return {?}
      */
      key => {
        if (customHeaders.has(key)) {
          requestHeaders = requestHeaders.set(key, customHeaders.get(key));
        }
      });
    }
    return requestHeaders;
  }
  /**
   * @protected
   * @template T
   * @param {?} res
   * @param {?} attributesMetadata
   * @param {?} modelType
   * @return {?}
   */
  resetMetadataAttributes(res, attributesMetadata, modelType) {
    for (const propertyName in attributesMetadata) {
      if (attributesMetadata.hasOwnProperty(propertyName)) {
        /** @type {?} */
        const metadata = attributesMetadata[propertyName];
        if (metadata.hasDirtyAttributes) {
          metadata.hasDirtyAttributes = false;
        }
      }
    }
    // @ts-ignore
    res[AttributeMetadataIndex$1] = attributesMetadata;
    return res;
  }
  /**
   * @protected
   * @template T
   * @param {?} model
   * @param {?} relationships
   * @return {?}
   */
  updateRelationships(model, relationships) {
    /** @type {?} */
    const modelsTypes = Reflect.getMetadata('JsonApiDatastoreConfig', this.constructor).models;
    for (const relationship in relationships) {
      if (relationships.hasOwnProperty(relationship) && model.hasOwnProperty(relationship)) {
        /** @type {?} */
        const relationshipModel = model[relationship];
        /** @type {?} */
        const hasMany = Reflect.getMetadata('HasMany', relationshipModel);
        /** @type {?} */
        const propertyHasMany = find(hasMany,
        /**
        * @param {?} property
        * @return {?}
        */
        property => {
          return modelsTypes[property.relationship] === model.constructor;
        });
        if (propertyHasMany) {
          relationshipModel[propertyHasMany.propertyName] = relationshipModel[propertyHasMany.propertyName] || [];
          /** @type {?} */
          const indexOfModel = relationshipModel[propertyHasMany.propertyName].indexOf(model);
          if (indexOfModel === -1) {
            relationshipModel[propertyHasMany.propertyName].push(model);
          } else {
            relationshipModel[propertyHasMany.propertyName][indexOfModel] = model;
          }
        }
      }
    }
    return model;
  }
  /**
   * @protected
   * @param {?} model
   * @return {?}
   */
  getModelPropertyNames(model) {
    return Reflect.getMetadata('AttributeMapping', model) || [];
  }
  /**
   * @private
   * @param {?=} customOptions
   * @return {?}
   */
  buildRequestOptions(customOptions = {}) {
    /** @type {?} */
    const httpHeaders = this.buildHttpHeaders(customOptions.headers);
    /** @type {?} */
    const requestOptions = Object.assign(customOptions, {
      headers: httpHeaders
    });
    return Object.assign(this.globalRequestOptions, requestOptions);
  }
  /**
   * @private
   * @param {?} params
   * @return {?}
   */
  _toQueryString(params) {
    return stringify(params, {
      arrayFormat: 'brackets'
    });
  }
}
JsonApiDatastore.decorators = [{
  type: Injectable
}];
/** @nocollapse */
JsonApiDatastore.ctorParameters = () => [{
  type: HttpClient
}];
if (false) {
  /**
   * @type {?}
   * @protected
   */
  JsonApiDatastore.prototype.config;
  /**
   * @type {?}
   * @private
   */
  JsonApiDatastore.prototype.globalHeaders;
  /**
   * @type {?}
   * @private
   */
  JsonApiDatastore.prototype.globalRequestOptions;
  /**
   * @type {?}
   * @private
   */
  JsonApiDatastore.prototype.internalStore;
  /**
   * @type {?}
   * @private
   */
  JsonApiDatastore.prototype.toQueryString;
  /**
   * @type {?}
   * @protected
   */
  JsonApiDatastore.prototype.http;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/** @type {?} */
const PROVIDERS = [JsonApiDatastore];

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
class JsonApiModule {}
JsonApiModule.decorators = [{
  type: NgModule,
  args: [{
    providers: [PROVIDERS],
    exports: [HttpClientModule]
  }]
}];

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

export { Attribute, BelongsTo, DEFAULT_OPTIONS, ErrorResponse, HasMany, JsonApiDatastore, JsonApiDatastoreConfig, JsonApiMetaModel, JsonApiModel, JsonApiModelConfig, JsonApiModule, JsonApiNestedModel, JsonApiQueryData, JsonAttribute, JsonModelConverter, NestedAttribute, PROVIDERS };
